<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Church Visit/Note Details</h1>
    </page-header>

    <page-body>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">
          {{ translations.tcChurchVisitNoteDetails }}
        </h1>
        <div class="d-flex mb-2">
          <iMapPoint />
          <h6 style="margin-left: 20px">{{ singleVisit.church_name }}</h6>
        </div>
        <div style="margin: -11px 0 20px 0">
          <h6 style="margin-left: 40px">{{ activeChurch.location.address }}</h6>
          <h6 style="margin-left: 40px">
            {{ activeChurch.location.city }} {{ activeChurch.location.state }} {{ activeChurch.location.zip }}
          </h6>
        </div>
        <div class="d-flex mb-3">
          <iCalendar />
          <p style="margin-left: 20px">
            {{ translatedDate }}
          </p>
        </div>
        <section class="section-4 bg-block mb-4">
          <div class="body" v-if="Section3">
            <div class="presentation-details">
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcContactedBy }}:</span>
                <span class="value">{{ singleVisit.name }}</span>
              </div>
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcVisitType }}:</span>
                <span class="value">{{ visitType }}</span>
              </div>
              <div class="font-weight-bold">
                <span class="label"></span>
                <span class="value"></span>
              </div>
            </div>
          </div>
        </section>
        <section class="section-4 bg-block mb-4">
          <div class="body">
            <div class="presentation-details notes">
              <div class="font-weight-bold">
                <span class="label">{{ translations.tcNotes }}:</span>
                <span class="value keep-breaks">{{ singleVisit.nte_note }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-5">
          <div class="body">
            <div class="d-flex action-buttons">
              <b-button
                variant="primary"
                v-if="iCanSeeAny(secured_note_edit_controls)"
                @click="handleEditButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
              >
                {{ translations.tcEdit }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd">
                {{ translations.tcCancel }}
              </b-button>
              <b-button
                variant="secondary"
                v-if="iCanSeeAny(secured_note_delete_controls)"
                @click="handleDeleteButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd"
              >
                {{ translations.tcDelete }}
              </b-button>
            </div>
          </div>
        </section>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iMapPoint from '@/assets/svgs/iMapPoint.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'view-church-visit',
  mixins: [translationMixin],
  data() {
    return {
      Section3: true,
      secured_note_delete_controls: {
        delete_church_visit_note_button: '6fb062d9-d458-4ce6-8681-692d8f05d870',
      },
      secured_note_edit_controls: {
        edit_church_visit_note_button: '200fdb2c-5ed2-4de4-b0cc-f44de87227c4',
      },
      toggle: true,
      translatedDate: '',
      translations: {},
      visitType: '',
    }
  },
  methods: {
    ...mapActions({
      addUpdateChurchVisitNote: 'churchMinistry/addUpdateChurchVisitNote',
      deleteChurchVisitNote: 'churchMinistry/deleteChurchVisitNote',
      getSingleVisit: 'churchMinistry/getSingleVisit',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async handleEditButtonClick() {
      await this.setSelectedVisitKey(this.selectedVisitKey)
      this.setRouterBackLink(this.$route.name)
      this.$router.push('/programs/cm/churchProfile/add-church-visit')
    },
    async handleCancelButtonClick() {
      this.setSelectedVisitKey()
      let linkBack = this.userRouterBackLink
      this.setRouterBackLink('')
      if (linkBack.length > 0) {
        this.$router.push({ name: linkBack })
      } else {
        this.$router.push('/programs/cm/church-visits-card')
      }
    },
    async handleDeleteButtonClick() {
      let result = true
      await Promise.all([
        this.setLoadingStatus(true),
        (result = await this.deleteChurchVisitNote(this.singleVisit)),
      ]).then(() => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result ? 'success' : 'error',
          text: result
            ? this.translations.tcVisitDeleted
            : this.translations.tcErrorDuringDeletion,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          if (result.value) {
            this.setSelectedVisitKey(null)
            let linkBack = this.userRouterBackLink
            this.setRouterBackLink('')
            if (linkBack.length > 0) {
              this.$router.push({ name: linkBack })
            } else {
              this.$router.push('/programs/cm/church-visits-card')
            }
          }
        })
      })
    },
    async page_load() {
      this.setLoadingStatus(true)
      try {
        await Promise.all([
          await this.getTranslations(),
          this.getComponentTranslations('common.church-visit-types', 'common.days', 'common.months'),
          await this.getSingleVisit(this.selectedVisitKey),
        ]).then(results => {
          this.stripReadableText(results[1])
          const translatedText = {
            common: { ...this.translations.common }
          }
          this.$set(this.translations, 'components', translatedText)
          this.updateDynamicText()
        })
      } catch (e) {
        console.error('Error in ViewChurchVisit, page_load(), ', e)
      } finally {
        await this.setLoadingStatus(false)
      }
    },
    updateDynamicText() {
      this.translatedDate = this.returnFormattedDate(this.singleVisit.nte_contact_date)

      const tempVisitType = this.singleVisit.nte_ctt_key
      this.visitType = this.translations.common['church-visit-types'][tempVisitType] || tempVisitType
    },
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      activeChurch: 'churchMinistry/churchDetail',
      iCanSeeAny: 'user/iCanSeeAny',
      prefCulture: 'user/userPreferredCulture',
      selectedVisitKey: 'churchMinistry/selected_visit_key',
      singleVisit: 'churchMinistry/singleVisit',
      userRouterBackLink: 'user/userRouterBackLink',
    }),
  },
  components: {
    iCalendar: iCalendar,
    iMapPoint: iMapPoint,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
.keep-breaks {
  white-space: pre-wrap;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1 {
  .d-flex {
    align-items: center;
  }
  i {
    width: 22px;
    font-size: 0;
    text-align: center;
    svg {
      display: inline-block;
    }
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .address {
    padding-left: 46px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.presentation-specs,
.presentation-details {
  max-width: 680px;
  column-gap: 40px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #979797;
  @include breakpoint(sm) {
    columns: 1;
    column-gap: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  div {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      color: #636363;
      font-weight: 700;
      line-height: 22px;
    }
    .label {
      display: block;
      margin-right: 0.25rem;
      text-transform: uppercase;
    }
  }
}

.presentation-details.notes {
  columns: 1;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: middle;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    button {
      min-width: 280px;
    }

    &.action-buttons {
      button {
        min-width: inherit;
      }
    }
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
